// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.addCategoryBtn{
    margin: 10px auto;
    margin-left: 10px;
    padding: 10px 20px;
    background-color: #ff80bf;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.inputNewCategory{
    padding: 10px 20px;
}`, "",{"version":3,"sources":["webpack://./src/myblog/CategorySetting.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,iBAAiB;IACjB,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".addCategoryBtn{\n    margin: 10px auto;\n    margin-left: 10px;\n    padding: 10px 20px;\n    background-color: #ff80bf;\n    color: white;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n}\n\n.inputNewCategory{\n    padding: 10px 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
