// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* 글로벌 스타일 */
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

/* Main 스타일 */
main {
  flex: 1 1;
  padding: 1rem;
}
/* Footer 스타일 */
footer {
  background-color: #FFE6FA;
  text-align: center;

}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,YAAY;AACZ;EACE,SAAS;EACT,UAAU;EACV,YAAY;AACd;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAO;AACT;;AAEA,aAAa;AACb;EACE,SAAO;EACP,aAAa;AACf;AACA,eAAe;AACf;EACE,yBAAyB;EACzB,kBAAkB;;AAEpB","sourcesContent":["/* 글로벌 스타일 */\nbody, html {\n  margin: 0;\n  padding: 0;\n  height: 100%;\n}\n\n#root {\n  display: flex;\n  flex-direction: column;\n  min-height: 100vh;\n}\n\n.App {\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n}\n\n/* Main 스타일 */\nmain {\n  flex: 1;\n  padding: 1rem;\n}\n/* Footer 스타일 */\nfooter {\n  background-color: #FFE6FA;\n  text-align: center;\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
