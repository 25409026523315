// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: #FFE6FA;
    flex-wrap: nowrap; 
  }
  
  .header__logo h1 {
    margin: 0;
    font-size: 1.5rem;
  }
  
  .header__nav ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    gap: 1rem;
  }
  
  .header__nav a {
    text-decoration: none;
    color: #333;
  }
  .header__auth {
    display: flex;
    align-items: center;
    background-color: #FFE6FA;
    
    flex-wrap: nowrap;
  }
  .header__auth a {
    text-decoration: none;
    color: #007bff;
  }
  
  .header__auth a:hover {
    text-decoration: underline;
  }
  .header__logo-img {
    width: 50px;
    height: 50px;
  }

  @media(max-width: 403px){
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      background-color: #FFE6FA;
      flex-wrap:nowrap; 
    }
  }`, "",{"version":3,"sources":["webpack://./src/structure/Header.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,WAAW;IACX,yBAAyB;IACzB,iBAAiB;EACnB;;EAEA;IACE,SAAS;IACT,iBAAiB;EACnB;;EAEA;IACE,qBAAqB;IACrB,SAAS;IACT,UAAU;IACV,aAAa;IACb,SAAS;EACX;;EAEA;IACE,qBAAqB;IACrB,WAAW;EACb;EACA;IACE,aAAa;IACb,mBAAmB;IACnB,yBAAyB;;IAEzB,iBAAiB;EACnB;EACA;IACE,qBAAqB;IACrB,cAAc;EAChB;;EAEA;IACE,0BAA0B;EAC5B;EACA;IACE,WAAW;IACX,YAAY;EACd;;EAEA;IACE;MACE,aAAa;MACb,8BAA8B;MAC9B,mBAAmB;MACnB,WAAW;MACX,yBAAyB;MACzB,gBAAgB;IAClB;EACF","sourcesContent":[".header {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    width: 100%;\n    background-color: #FFE6FA;\n    flex-wrap: nowrap; \n  }\n  \n  .header__logo h1 {\n    margin: 0;\n    font-size: 1.5rem;\n  }\n  \n  .header__nav ul {\n    list-style-type: none;\n    margin: 0;\n    padding: 0;\n    display: flex;\n    gap: 1rem;\n  }\n  \n  .header__nav a {\n    text-decoration: none;\n    color: #333;\n  }\n  .header__auth {\n    display: flex;\n    align-items: center;\n    background-color: #FFE6FA;\n    \n    flex-wrap: nowrap;\n  }\n  .header__auth a {\n    text-decoration: none;\n    color: #007bff;\n  }\n  \n  .header__auth a:hover {\n    text-decoration: underline;\n  }\n  .header__logo-img {\n    width: 50px;\n    height: 50px;\n  }\n\n  @media(max-width: 403px){\n    .header {\n      display: flex;\n      justify-content: space-between;\n      align-items: center;\n      width: 100%;\n      background-color: #FFE6FA;\n      flex-wrap:nowrap; \n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
