// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.question-mark-container {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  
  .tooltip {
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 0.5rem;
    padding: 0.5rem;
    background-color: #FFE6FA;
    color: gray;
    border-radius: 0.25rem;
    font-size: 0.75rem;
    white-space: nowrap;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    z-index: 100;
    
  }
  .tooltip-follow {
    position: absolute;
    bottom: 100%;
    left: 0%;
    transform: translateX(-80%);
    margin-bottom: 0.5rem;
    padding: 0.5rem;
    background-color: #FFE6FA;
    color: gray;
    border-radius: 0.25rem;
    font-size: 0.75rem;
    white-space: nowrap;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    z-index: 100;
  }
  @media (max-width: 600px){
    .tooltip {
      white-space: nowrap;
      bottom: 100%;
      left: 0%;
      transform: translateX(-70%);
    }
    .tooltip-follow {
      white-space: nowrap;
      bottom: 100%;
      left: 0%;
      transform: translateX(-100%);
    }
  }`, "",{"version":3,"sources":["webpack://./src/resource/QuestionMark.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,qBAAqB;IACrB,eAAe;EACjB;;EAEA;IACE,kBAAkB;IAClB,YAAY;IACZ,SAAS;IACT,2BAA2B;IAC3B,qBAAqB;IACrB,eAAe;IACf,yBAAyB;IACzB,WAAW;IACX,sBAAsB;IACtB,kBAAkB;IAClB,mBAAmB;IACnB,0CAA0C;IAC1C,YAAY;;EAEd;EACA;IACE,kBAAkB;IAClB,YAAY;IACZ,QAAQ;IACR,2BAA2B;IAC3B,qBAAqB;IACrB,eAAe;IACf,yBAAyB;IACzB,WAAW;IACX,sBAAsB;IACtB,kBAAkB;IAClB,mBAAmB;IACnB,0CAA0C;IAC1C,YAAY;EACd;EACA;IACE;MACE,mBAAmB;MACnB,YAAY;MACZ,QAAQ;MACR,2BAA2B;IAC7B;IACA;MACE,mBAAmB;MACnB,YAAY;MACZ,QAAQ;MACR,4BAA4B;IAC9B;EACF","sourcesContent":[".question-mark-container {\n    position: relative;\n    display: inline-block;\n    cursor: pointer;\n  }\n  \n  .tooltip {\n    position: absolute;\n    bottom: 100%;\n    left: 50%;\n    transform: translateX(-50%);\n    margin-bottom: 0.5rem;\n    padding: 0.5rem;\n    background-color: #FFE6FA;\n    color: gray;\n    border-radius: 0.25rem;\n    font-size: 0.75rem;\n    white-space: nowrap;\n    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);\n    z-index: 100;\n    \n  }\n  .tooltip-follow {\n    position: absolute;\n    bottom: 100%;\n    left: 0%;\n    transform: translateX(-80%);\n    margin-bottom: 0.5rem;\n    padding: 0.5rem;\n    background-color: #FFE6FA;\n    color: gray;\n    border-radius: 0.25rem;\n    font-size: 0.75rem;\n    white-space: nowrap;\n    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);\n    z-index: 100;\n  }\n  @media (max-width: 600px){\n    .tooltip {\n      white-space: nowrap;\n      bottom: 100%;\n      left: 0%;\n      transform: translateX(-70%);\n    }\n    .tooltip-follow {\n      white-space: nowrap;\n      bottom: 100%;\n      left: 0%;\n      transform: translateX(-100%);\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
