// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* NotFound.css */
.not-found-container {
    text-align: center;
    padding: 50px;
  }
  
  .not-found-container h1 {
    font-size: 3rem;
    color: #ff6b6b;
  }
  
  .not-found-container p {
    font-size: 1.5rem;
    color: #555;
  }
  
  .not-found-container .go-home-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 1.2rem;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .not-found-container .go-home-button:hover {
    background-color: #45a049;
  }
  `, "",{"version":3,"sources":["webpack://./src/main/NotFound.css"],"names":[],"mappings":"AAAA,iBAAiB;AACjB;IACI,kBAAkB;IAClB,aAAa;EACf;;EAEA;IACE,eAAe;IACf,cAAc;EAChB;;EAEA;IACE,iBAAiB;IACjB,WAAW;EACb;;EAEA;IACE,gBAAgB;IAChB,kBAAkB;IAClB,iBAAiB;IACjB,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":["/* NotFound.css */\n.not-found-container {\n    text-align: center;\n    padding: 50px;\n  }\n  \n  .not-found-container h1 {\n    font-size: 3rem;\n    color: #ff6b6b;\n  }\n  \n  .not-found-container p {\n    font-size: 1.5rem;\n    color: #555;\n  }\n  \n  .not-found-container .go-home-button {\n    margin-top: 20px;\n    padding: 10px 20px;\n    font-size: 1.2rem;\n    background-color: #4CAF50;\n    color: white;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n  }\n  \n  .not-found-container .go-home-button:hover {\n    background-color: #45a049;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
