import React from 'react';

const Footer = () => {
  return (
    <footer>
      <p>&copy; 2024 내 웹사이트. 모든 권리 보유.</p>
    </footer>
  );
};

export default Footer;
