// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.post-image {
    width: 100%;
    height: auto; /* 최신 글 한 칸의 높이에 맞춤 */
    object-fit: cover; /* 이미지 비율을 유지하면서 박스에 맞춤 */
    margin-bottom: 10px; /* 콘텐츠와의 간격 */
  }`, "",{"version":3,"sources":["webpack://./src/main/NewPost.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY,EAAE,qBAAqB;IACnC,iBAAiB,EAAE,yBAAyB;IAC5C,mBAAmB,EAAE,aAAa;EACpC","sourcesContent":[".post-image {\n    width: 100%;\n    height: auto; /* 최신 글 한 칸의 높이에 맞춤 */\n    object-fit: cover; /* 이미지 비율을 유지하면서 박스에 맞춤 */\n    margin-bottom: 10px; /* 콘텐츠와의 간격 */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
