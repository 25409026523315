// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.category-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.arrow {
  width: 15px;
  height: 15x;
  margin-right:10px;
}


.categories{
    color: #555555;
}

ul {
    list-style-type: none;
}   


.level2-0 {
    margin-left: 8px;
    padding: 5px;
    font-size: 15px;
    /* border: 1px solid #fea6d2; */
    border-radius: 5px;
    margin: 5px 0;
   
  }
  
  .level2-1 {
    margin-left: 10px;
    padding: 5px;
    font-size: 12px;

    margin: 5px 0;

  }
  
  .level2-2 {
    margin-left: 20px;
    padding: 5px;
    font-size: 10px;
    margin: 5px 0;
  
  }`, "",{"version":3,"sources":["webpack://./src/myblog/CategoryListForMain.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,WAAW;EACX,iBAAiB;AACnB;;;AAGA;IACI,cAAc;AAClB;;AAEA;IACI,qBAAqB;AACzB;;;AAGA;IACI,gBAAgB;IAChB,YAAY;IACZ,eAAe;IACf,+BAA+B;IAC/B,kBAAkB;IAClB,aAAa;;EAEf;;EAEA;IACE,iBAAiB;IACjB,YAAY;IACZ,eAAe;;IAEf,aAAa;;EAEf;;EAEA;IACE,iBAAiB;IACjB,YAAY;IACZ,eAAe;IACf,aAAa;;EAEf","sourcesContent":[".category-item {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.arrow {\n  width: 15px;\n  height: 15x;\n  margin-right:10px;\n}\n\n\n.categories{\n    color: #555555;\n}\n\nul {\n    list-style-type: none;\n}   \n\n\n.level2-0 {\n    margin-left: 8px;\n    padding: 5px;\n    font-size: 15px;\n    /* border: 1px solid #fea6d2; */\n    border-radius: 5px;\n    margin: 5px 0;\n   \n  }\n  \n  .level2-1 {\n    margin-left: 10px;\n    padding: 5px;\n    font-size: 12px;\n\n    margin: 5px 0;\n\n  }\n  \n  .level2-2 {\n    margin-left: 20px;\n    padding: 5px;\n    font-size: 10px;\n    margin: 5px 0;\n  \n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
