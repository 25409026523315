// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-buttons{
  display: flex;
  justify-content: center; /* 버튼들을 수평으로 중앙 정렬 */
  margin-bottom: 20px; /* 아래 여백 추가 */
  flex-wrap: wrap;
  gap: 10px; /* 버튼 사이 간격 */
}`, "",{"version":3,"sources":["webpack://./src/main/AllNewNotification.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB,EAAE,oBAAoB;EAC7C,mBAAmB,EAAE,aAAa;EAClC,eAAe;EACf,SAAS,EAAE,aAAa;AAC1B","sourcesContent":[".filter-buttons{\n  display: flex;\n  justify-content: center; /* 버튼들을 수평으로 중앙 정렬 */\n  margin-bottom: 20px; /* 아래 여백 추가 */\n  flex-wrap: wrap;\n  gap: 10px; /* 버튼 사이 간격 */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
